import { ElementType } from 'react';

import { GLegacyTeaser, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';
import { BreakingStoryBlockTeaser, EvolvingStoryTeaser, OpinionBlockTeaser } from '@hubcms/ui-teaser';

export const parseTeaser: TeaserGridItemParserFn<GLegacyTeaser> = (item, idx) => {
  const key = item.data.teaserData.id;

  const teaserElementData = mapTeaser(item.data, idx);
  if (!teaserElementData) {
    return {
      key,
      Component: 'div',
    };
  }

  return {
    ...teaserElementData,
    key,
  };
};

// TODO: CW-624, CW-625 - This can be removed
type ParsedTeaser<E extends ElementType> = Pick<
  ReturnType<TeaserGridItemParserFn<GLegacyTeaser, E>>,
  'Component' | 'componentProps'
>;

function mapTeaser(data: GLegacyTeaser['data'], idx: number) {
  switch (data.options.sm) {
    case 'opinion': {
      const ret: ParsedTeaser<typeof OpinionBlockTeaser> = {
        Component: OpinionBlockTeaser,
        componentProps: {
          data: data.teaserData,
          hideIntro: data.options.hideIntro,
          odd: idx % 2 === 0,
          mobileRow: idx !== 0,
        },
      };
      return ret;
    }
    case 'breaking': {
      const ret: ParsedTeaser<typeof BreakingStoryBlockTeaser> = {
        Component: BreakingStoryBlockTeaser,
        componentProps: {
          data: {
            ...data.teaserData,
            image: typeof data.teaserData.image === 'string' ? null : data.teaserData.image,
            trackingData: data.trackingData ?? {},
          },
          imageFormat: data.imageFormat,
        },
      };
      return ret;
    }
    case 'breaking-slider': {
      const ret: ParsedTeaser<typeof EvolvingStoryTeaser> = {
        Component: EvolvingStoryTeaser,
        componentProps: {
          data: {
            ...data.teaserData,
            image: typeof data.teaserData.image === 'string' ? null : data.teaserData.image,
            trackingData: data.trackingData ?? {},
          },
          imageFormat: data.imageFormat,
        },
      };
      return ret;
    }
    default:
      return null;
  }
}
